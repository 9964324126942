.sidebar.pro-inner-item {
    padding: 0.5rem 0.9rem 0.5rem 0.9rem;
}

.sidebar {
    z-index: 1005 !important;
}

.sidebar-name {
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    color: #fff;
    background-color: #1E1E1E;
}

.item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-item {
    color: #000000;
    padding-left: 1rem;
    width: 90%;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    cursor: pointer;
    margin-top: 0.5rem;
    border-radius: 10px;
}

.sidebar-item:hover {
    background-color: #00000020;
}

.active {
    border: 2px solid #aca6a6 !important;
    background-color: #aca6a6;
    color: #fff !important;
}

.active:hover {
    border: 2px solid #aca6a6 !important;
    color: #fff !important;
}

a {
    text-decoration: none !important;
}

.logout-btn {
    cursor: pointer;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 3% 3% 3% 2% !important;
}

.icon-container {
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.icon-names {
    font-size: small;
    margin-left: 10px;
}

.fs-small {
    font-size: small
}

.sidebar-icon {
    font-size: 25px !important;
    margin-right: 15px !important;
}

.sidebar-header {
    transition: all 0.3s ease;
}

.main-logo-container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 8px;
    /* Add a small padding if needed */
}

.logo-image {
    flex-shrink: 0;
    /* Prevents the image from moving */
}

.main-logo-text {
    font-size: xx-large;
}

.sidebar-header .p-3:not(.collapsed) .logo-text {
    opacity: 1;
    position: relative;
    /* Repositions the text next to the logo */
}

.basic-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1000;
    background: transparent;
    box-shadow: none;
}

.header-content {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-icon {
    color: #fff;
    cursor: pointer;
    padding-bottom: 4px;
}

.dialog-container {
    border: none;
    border-radius: 8px;
    padding: 0;
    max-width: 500px;
    width: 90%;
    background: rgb(25 25 62);
    /* Dark blue background */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
    position: relative;
    outline: none;
}

.dialog-overlay {
    position: fixed;
    /* Makes the overlay cover the entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent dark background */
    z-index: 999;
    /* Ensure the overlay is above the background but below the dialog */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Centers the dialog */
}

.dialog-content {
    padding: 24px;
    text-align: center;
    color: #ffffff;
}

.dialog-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    /* White text for better contrast */
    margin-bottom: 12px;
}

.dialog-description {
    font-size: 1rem;
    color: #d4d4d4;
    /* Lighter gray text */
    margin-bottom: 20px !important;
}

.dialog-actions {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.dialog-button {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dialog-cancel {
    background: #2E3A8A;
    /* Slightly lighter dark blue */
    color: #d4d4d4;
}

.dialog-cancel:hover {
    background: #3A4D8A;
    /* Darker dark blue on hover */
}

.dialog-confirm {
    background: #3B82F6;
    /* Dark blue confirmation button */
    color: #ffffff;
    box-shadow: 0 2px 6px rgba(59, 130, 246, 0.4);
}

.dialog-confirm:hover {
    background: #2563eb;
    /* Slightly darker dark blue on hover */
}