@font-face {
  font-family: 'Nexa Extra Light'; 
  src: url('./Fonts/Nexa-ExtraLight.ttf') format('truetype'); 
  font-weight: normal;
  font-style: normal;
}


* {
  font-family: 'Nexa Extra Light';
}

p {
  margin-bottom: 0;
}