:root {
  --theme-bg-light: #19193e;
}

.welcome-grid {
  align-content: center;
  font-size: 80px;
  position: fixed;
}

.logo-container {
  display: flex;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 20px;
}

.logo-image {
  height: 40px;
  width: auto;
}

.logo-text {
  font-size: xx-large;
  color: white;
  font-weight: bold;
}


.ellipse {
  background: linear-gradient(180deg,
      rgb(0, 0, 41) 0%,
      rgb(51, 0, 122.5) 63.4%,
      rgb(102, 0, 204) 100%);
  border-radius: 50%;
  height: 300px;
  left: 40%;
  position: fixed;
  top: 5%;
  width: 300px;
}


.bottom-ellipse {
  width: 200px !important;
  height: 200px;
  background: linear-gradient(180deg,
      rgb(0, 0, 41) 0%,
      rgb(51, 0, 122.5) 63.4%,
      rgb(102, 0, 204) 100%);
  border-radius: 50%;
  position: fixed;
  left: 75%;
  bottom: 8px;
  transform: translateX(-50%);
  z-index: 500;
}

.guests-list {
  white-space: normal;
  /* Allows wrapping within the container */
  word-wrap: break-word;
  /* Ensures long words break if necessary */
  overflow-wrap: break-word;
  /* Ensures long words break */
  word-break: break-all;
  /* Breaks long words if they exceed container width */
  hyphens: auto;
  /* Allows hyphenation where appropriate */
}

.login-grid {
  padding: 5%;
  border: 1px solid gray;
  border-radius: 10px;
  width: 75%;
  background: #00002990;
}

.forgot-password-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  background-color: #000029;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(6 5 5 / 50%);
  z-index: 1000;
}


.login-title {
  font-size: 2em;
  color: #fff;
}

.login-subtitle {
  color: #fff;
}

.input-label {
  color: #fff !important;
}

.input-field .MuiInputBase-input {
  color: #fff !important;
}

.input-field .MuiFormLabel-root {
  color: #fff !important;
}

.input-field .MuiOutlinedInput-root {
  border-color: #fff !important;
}

.input-field .MuiOutlinedInput-root fieldset {
  border-color: #fff !important;
}

.remember-me {
  color: #fff;
  display: block;
  text-align: left;
  margin-bottom: 2%;
}

.login-button {
  background: linear-gradient(to right, #472DDB, #0985FB, #00D7FD);
  color: #fff;
  width: 100%;
  margin-top: 2%;
}

.forgot-password {
  margin-top: 2%;
  text-align: center;
}

.text-center {
  text-align: center;
}

.forgot-password a {
  color: #00aaff;
  text-decoration: none;
}

.or-section {
  display: flex;
  align-items: center;
  color: #939090;
  font-size: 1.2em;
}

.or-section span {
  margin: 0 2%;
  color: #939090;
  position: relative;
}

.or-section::before,
.or-section::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #939090;
  margin: 0 1%;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.signup-text {
  margin-top: 10%;
  color: #fff;
  text-align: center;
}

.signup-text a {
  color: #00aaff;
  text-decoration: none;
}

.footer-links {
  margin-top: 5%;
  text-align: center;
}

.footer-links a {
  color: #00aaff;
  text-decoration: none;
  margin-left: 2%;

}

.schedule-btn {
  background: linear-gradient(to right, #32aaff, #6495ed);
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: bold;
  color: #fff !important;
  border: none;
  width: 20%
}

.card-text {
  font-size: smaller;
}

.device-select select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  background-color: #2a2a5b;
  color: #fff;
}

.control-btn {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.join-btn {
  padding: 10px;
  border-radius: 5px;
  background: linear-gradient(90deg, #1a73e8, #42a5f5);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.preview-video {
  width: 100%;
  height: 35vh;
  border-radius: 10px;
  background-color: #000000;
}

.join-divider {
  height: 50vh !important;
  width: 1px !important;
  background: white !important;
  place-self: center;
}

.record-btn {
  background-color: green !important;
  color: white !important;
}

.studio-icons {
  background: var(--theme-bg-light) !important;
  border-radius: 5px !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  width: 3rem !important;
  font-size: large !important;
}

.bannerData {
  background: #ffffff10;
  border-radius: 10px;
  color: white;
  padding: 4%;
  margin-bottom: 5%;
}

.ai-accordion {
  background: #ffffff10;
  color: white;
}

.show-btn {
  background-color: white;
  color: black;
}

.cancel-btn {
  background-color: white !important;
  color: black !important;
}

.more-guests {
  color: #ffdd57;
  /* Highlight the +N with a different color */
  font-weight: bold;
  cursor: pointer;
  /* text-decoration: underline;  Optional, to emphasize it as interactive */
}

.image-loader-overlay {
  min-height: 175px;
  align-content: center;
  text-align: center;
  background: darkslategray;
  border-radius: 10px;
}

.links {
  color: #00aaff;
  cursor: pointer;
}

.head-text {
  position: relative;
}

.text-on-image {
  position: absolute;
  right: 50%;
  left: 50%;
  bottom: 15%;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-tabs-container .nav-tabs .custom-tab-unique {
  color: white;
  background-color: transparent;
  border: 1px solid transparent;
}

.custom-tabs-container .nav-tabs .custom-tab-unique:hover,
.custom-tabs-container .nav-tabs .custom-tab-unique:focus {
  border: none;
  outline: none;
}

.custom-tabs-container .nav-tabs .custom-tab-unique.active {
  background-color: grey;
  color: white;
  border: none;
}

.remove-space .nav-item {
  flex-basis: auto !important;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px 0;
}

.tag-chip {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 5px 10px;
  font-size: 14px;
  color: #333;
  cursor: default;
  /* Change to pointer if editable in future */
  user-select: none;
}

.tag-chip:hover {
  background-color: #e0e0e0;
  /* Optional hover effect */
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--theme-bg);
  color: white;
}

/* Ensure the text and labels in the toolbar are white */
.ql-toolbar.ql-snow .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker-item {
  color: white !important;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: white !important;
}

/* Make the placeholder text white */
.ql-editor::placeholder {
  color: white !important;
}

.ql-editor.ql-blank::before {
  color: white !important;
  font-style: normal !important;
  font-size: medium !important;
  color: lightgray !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background-color: var(--theme-bg);
}


/* Make icons and text in toolbar white when hovered */
.ql-toolbar.ql-snow .ql-picker-item:hover,
.ql-toolbar.ql-snow .ql-picker-label:hover,
.ql-toolbar.ql-snow .ql-stroke:hover {
  color: white !important;
  /* fill: white !important; */
}

/* Change the active button text and icon to white */
.ql-toolbar.ql-snow .ql-active {
  color: white !important;
  /* fill: white !important; */
}

/* Set the background of the toolbar to transparent (optional) */
.ql-toolbar.ql-snow {
  background-color: transparent !important;
  border-color: transparent !important;
}

/* Additional fix for certain toolbar elements (e.g., dropdown arrows) */
.ql-toolbar.ql-snow .ql-picker {
  color: white !important;
}

.ql-toolbar.ql-snow .ql-picker-item.ql-selected {
  color: white !important;
}

.ql-container.ql-snow {
  border-color: transparent !important;
}


.ql-snow .ql-stroke {
  stroke: white !important;
}

.auth-form {
  background-color: var(--theme-bg-light);
  overflow-wrap: break-word;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.auth-form h2 {
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
}

.auth-form input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.auth-form button {
  width: 100%;
  padding: 0.5rem;
  background-color: #0070f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-form button:hover {
  background-color: #0051a2;
}

.auth-form p {
  margin-top: 1rem;
  text-align: center;
}

.auth-form a {
  color: #0070f3;
  text-decoration: none;
}

.social-media-share__section {
  text-align: left;
  /* Align content to the left */
}

.social-media-share__heading {
  font-size: 24px;
  margin-bottom: 5px;
  color: #fff;
  border-top: 1px solid white;
  padding-top: 2%;
}

.social-media-share__icons {
  display: flex;
  justify-content: flex-start;
  /* Align icons to the left */
  gap: 20px;
  /* Spacing between icons */
}

.social-media-share__icon-container {
  position: relative;
  width: 50px;
  /* Square container */
  height: 50px;
  background-color: #f9f9f9;
  /* Light gray background for the container */
  border: 1px solid #ccc;
  /* Gray border around the square */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  /* Slight rounding for the edges */
  overflow: hidden;
}

.social-media-share__icon {
  font-size: 32px;
  /* Icon size */
  color: inherit;
  /* Icon color is inherited */
}

.social-media-share__icon.facebook {
  color: #3b5998;
  /* Facebook blue */
}

.social-media-share__icon.linkedin {
  color: #0077b5;
  /* LinkedIn blue */
}

.social-media-share__icon.spotify {
  color: #1db954;
  /* Spotify green */
}

.social-media-share__icon.itunes {
  color: #734f96;
  /* iTunes white */
}

.social-media-share__icon.youtube {
  color: #ff0000;
  /* YouTube red */
}

.social-media-share__icon.youtube:hover {
  cursor: pointer;
  /* Changes cursor on hover */
}

.social-media-share__icon.linkedin:hover {
  cursor: pointer;
  /* Changes cursor on hover */
}

.qr-code-container {
  display: flex;
  justify-content: center;
}

.icon-button {
  all: unset;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-button .tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  bottom: -25px;
  /* Adjust to position below the icon */
  white-space: nowrap;
  font-size: 12px;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s;
}

.icon-button:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.frame-slider-container {
  position: relative;
  height: 120px;
  background-color: #1e1e1e;
  border-radius: 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 5px;
  margin-left: 10px;
  /* Add margin to create left space */
  margin-right: 10px;
  /* Add margin to create right space */
}

.frame {
  flex: 1;
  background-size: cover;
  background-position: center;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s, opacity 0.3s ease-in-out;
}

.frame:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

.handle {
  position: absolute;
  width: 10px;
  height: calc(100% - 10px);
  background-color: #2968fe;
  border-radius: 4px;
  cursor: pointer;
  transform: translateX(-50%);
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.handle:hover {
  transform: scale(1.3) translateX(-50%);
  transition: transform 0.2s ease-in-out;
}

.handle-start {
  background-color: #41c6f0;
  left: 0;
  /* Ensure it starts at the very left initially */
}

.handle-end {
  background-color: #41c6f0;
  right: 0;
  /* Ensure it starts at the very right initially */
}

.highlight-bar {
  position: absolute;
  height: calc(100% - 10px);
  background: rgba(41, 104, 254, 0.2);
  /* Light blue with low opacity */
  border-radius: 8px;
  top: 5px;
  z-index: 1;
  pointer-events: none;
}

/* Frames wrapper to add padding inside the slider */
.frames-wrapper {
  position: relative;
  display: flex;
  flex: 0.5;
  height: 100%;
  overflow: hidden;
  padding-left: 5px;
  /* Add padding to create a gap inside */
  padding-right: 5px;
}

/* Frames container for internal alignment */
.frames-container {
  display: flex;
  flex: 1;
  gap: 1px;
  /* Add small gaps between frames */
}

.social-media-share__hover-message {
  transform: translateX(-50%);
  /* Center it horizontally */
  background-color: #333;
  /* Dark background to fit dark theme */
  color: #f9f9f9;
  /* Light text color for contrast */
  padding: 10px 15px;
  /* Comfortable padding */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
  /* Stronger shadow for depth */
  font-size: 14px;
  /* Slightly smaller text */
  max-width: 250px;
  /* Restrict the width for neatness */
  white-space: normal;
  /* Allow text to wrap if needed */
  line-height: 1.4;
  /* Ensure good line spacing */
  z-index: 1000;
  /* Ensure it appears above other elements */
  animation: fadeIn 0.3s ease-in-out;
  /* Optional fade-in effect */
  display: flex;
  align-items: center;
  /* Vertically center content */
  justify-content: center;
  /* Horizontally center content */
}

.social-media-share__info-icon {
  font-size: 18px;
  /* Set a decent size for the icon */
  margin-right: 8px;
  /* Space between the icon and text */
  color: #ffb84d;
  /* Icon color (light gold for a nice contrast) */
}

button[aria-label="Previous month"] {
  color: white;
  /* Icon color */
}

button[aria-label="Next month"] {
  color: white;
  /* Icon color */
}

/* BannerStyle */

.banner-theme {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 10px;
}

.banner-theme-item {
  background: rgba(0, 0, 41, 0.80);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100px
}

.banner-theme-item:hover {
  border: 1px solid rgba(255, 255, 255, 0.50);
}
.banner-theme-item.active { 
  background: rgba(0, 0, 41, 0.80);
  border: 2px solid rgba(68, 66, 66, 0.5); 
}